import { Controller } from "stimulus";
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = ["builder"];

  static values = {
    programId: String,
    programModuleId: String
  }

  async verifyFormsAndClose() {
    try {
      await this.verify();

      this.close();
    } catch (e) {
      // no-op
    }
  }

  async verify() {
    const unloadChecker = this.application
      .getControllerForElementAndIdentifier(document.body, "unload-checker");

    return await unloadChecker.verify();
  }

  followUrl(event) {
    event.preventDefault();

    let { url } = event.currentTarget.dataset;

    this.verify().then(() => {
      try {
        ajax({
          url,
          type: 'get',
          dataType: 'script'
        });
      } catch (_e) {
        return;
      }
    }).catch(_e => {});
  }

  close() {
    this.builderTarget.classList.add('closed');

    setTimeout(() => {
      this.builderTarget.remove();
    }, 500);

    this._updateUrl();
    this._deselectAllCards();
    this._highlightModule();
  }

  _highlightModule() {
    if (!this.hasProgramModuleIdValue) {
      return;
    }

    let currentModule = document
      .querySelector(`.js-program-module-card-${this.programModuleIdValue}`);

    if (currentModule) {
      currentModule.classList.add('highlight');

      setTimeout(() => {
        currentModule.classList.remove('highlight');
      }, 3000);
    }
  }

  _updateUrl() {
    let url = `/admin/programs/${this.programIdValue}/program_modules`;

    window.history.pushState({}, "", url.split('?')[0]);
  }

  _deselectAllCards() {
    let selectedCards = document.querySelectorAll('.js-program-module .selected');

    selectedCards.forEach((card) => {
      card.classList.remove('selected');
    });
  }
}
