import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";

export default class extends Controller {
  static targets = ["content", "form"]

  static values = {
    originalText: String
  }

  toggleEditor() {
    this.formTarget.classList.remove('hidden');
    this.contentTarget.classList.add('hidden');

    let textArea = this._textArea();
    textArea.focus();
    textArea.selectionStart = textArea.value.length;
  }

  cancelEditing() {
    this.formTarget.classList.add('hidden');
    this.contentTarget.classList.remove('hidden');
    this._textArea().value = this.originalTextValue;
  }

  onInput(event) {
    event.preventDefault();

    this.formTarget.querySelector('.submit-button').click();
    this.formTarget.querySelector('.submit-button').disabled = true;
  }

  _textArea() {
    return this.formTarget.querySelector('.text-area-field');
  }
}
