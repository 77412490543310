import { Controller } from "stimulus";
import {ajax} from "@rails/ujs";
export default class extends Controller {
  static values = {
    programId: String,
    contentPageId: String,
    moduleId: String
  }
  connect() {
    if (this.contentPageIdValue || this.moduleIdValue) {
      this._updateUrl();
      this._loadBuilder(this._setContainerWidth);
    }
  }

  _loadBuilder(callback) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._url(),
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        if (callback) callback();
      }
    })
  }

  _url() {
    if (this.contentPageIdValue) {
      return `/admin/programs/${this.programIdValue}/content_pages/${this.contentPageIdValue}`;
    } else if (this.moduleIdValue) {
      return `/admin/programs/${this.programIdValue}/program_modules/${this.moduleIdValue}`;
    }
  }

  _updateUrl() {
    window.history.pushState({}, "", this._url());
  }

  _setContainerWidth() {
    const sidebarCollapsed = localStorage.getItem(`admin_sidebar_collapsed`) === 'true';

    if (sidebarCollapsed) {
      document.getElementById('program-builder').classList.add('sidebar-closed');
    }
  }
}
