import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let elementToHighlight = document.querySelector('.highlight');

    if (elementToHighlight) {
      this._highlightElement(elementToHighlight);
      this._scrollElementIntoView(elementToHighlight);
    }
  }

  _highlightElement(elem) {
    setTimeout(() => {
      elem.classList.remove('highlight');
    }, 3000);
  }

  _scrollElementIntoView(elem) {
    if (elem.getBoundingClientRect().right > window.innerWidth) {
      elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start"});
    }
  }
}
