import { Controller } from "stimulus";
import { Draggable } from "@shopify/draggable";
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static values = { programId: String };

  connect() {
    const container = this.element;

    this.draggable = new Draggable(container, {
      draggable: ".item",
      handle: ".handle",
    });

    this.draggable.on('mirror:created', this._onMirrorCreated.bind(this));

    this.draggable.on("drag:move", this._onDragMove.bind(this));

    this.draggable.on("drag:over", this._onDragOver.bind(this));

    this.draggable.on("drag:out", this._onDragOut.bind(this));

    this.draggable.on("drag:stop", this._onDragStop.bind(this));

    this.over = undefined;
  }

  disconnect() {
    this.draggable.destroy();
  }

  _onMirrorCreated(event) {
    const { mirror, _sourceContainer, source, _originalSource } = event.data;

    const dimensions = source.getBoundingClientRect();
    mirror.style.width = `${dimensions.width}px`;
    mirror.style.height = `${dimensions.height}px`;

    source.querySelector('.card-cover').classList.remove('hidden');

    if (mirror.classList.contains('js-program-module')) {
      const card = mirror.querySelector('.card-content');
      card.classList.remove('border-slate-300');
      card.classList.add('border-purple-600');
      card.classList.add('border-dashed');
    } else {
      mirror.classList.remove('border-slate-200');
      mirror.classList.add('border-purple-600');
      mirror.classList.add('border-dashed');
    }

    mirror.classList.add('z-50');
  }

  _onDragMove(event) {
    const source = event.data.source;
    let over = this.over

    if (!over) {
      return
    }

    if (!source.classList.contains('js-program-module')) {
      if (over.classList.contains('js-program-module')) {
        let topParentType = over.dataset.moduleType;

        if (!this._elementCanBeDropped(source, topParentType)) {
          return false;
        }

        // TODO see if it's worth keeping this piece of code
        // if (over.querySelectorAll('[class*="js-content-page-"]').length) {
        //   return false;
        // }
      } else {
        let topParentType = over.closest('.js-program-module').dataset.moduleType;

        if (!this._elementCanBeDropped(source, topParentType)) {
          return false;
        }
      }
    }

    if (source.classList.contains('js-program-module')) {
      this._dragProgramModule(event, source, over);
    } else {
      this._dragContentPage(event, source, over);
    }
  }

  _onDragOver(event) {
    const { source, over } = event.data;

    if (over) {
      if (source.classList.contains('js-program-module') && !over.classList.contains('js-program-module')) {
        this.over = over.closest('.js-program-module')
      } else {
        this.over = over;
      }

      this._removeAllDividers();
    }
  }

  _onDragOut(event) {
    if (!event.data.source.classList.contains('js-program-module')) {
      this.over = null;
    }
  }

  _onDragStop(event) {
    if (this.over) {
      const draggedElement = event.data.source;
      const originalSource = event.data.originalSource;

      if (draggedElement.classList.contains('content-page-card') && !this.over.classList.contains('content-page-card')) {
        this._insertIntoProgramModule(draggedElement);
      } else {
        this._insertBeforeOrAfterElement(event, draggedElement, originalSource);
      }
    }

    // Clean up
    this._removeAllDividers();
    this.over = null;
    this.contentPageIndex = null;
    this.before = null;
    this.after = null;
  }

  _reorderElement(elementType, elementId, otherElementId, placement) {
    const path = elementType == 'program_modules' ? 'reorder' : 'move';
    const url = `/admin/programs/${this.programIdValue}/${elementType}/${elementId}/${path}`;

    const body = new FormData();
    body.append('src_drag_and_drop', true);

    if (placement === 'after') {
      body.append('after_id', otherElementId);
    } else {
      body.append('before_id', otherElementId);
    }

    this._performRequest(
      url, 'PATCH', body, document.querySelector('.draggable--original')
    );
  }

  _moveContentPageIntoProgramModule(draggedElement, programModuleId) {
    const contentPageId = draggedElement.dataset.elementId;
    const url = `/admin/programs/${this.programIdValue}/content_pages/${contentPageId}/move`;

    const body = new FormData();
    body.append('src_drag_and_drop', true);
    body.append('module_id', programModuleId);

    this._performRequest(url, 'PATCH', body, draggedElement);
  }

  _dragProgramModule(event, source, over) {
    const sensorData = event.data.sensorEvent.data;
    const container = document.getElementsByClassName('program-overview-cards-container')[0];
    const threshold = 150;
    const scrollSpeed = 20;
    const windowWidth = window.innerWidth;
    const { clientX } = sensorData;
    const mouseX = clientX;
    const crossedBoundaries = this._calculateOverlapPercentage(sensorData, over);

    // Scroll the container if the mouse is near the edges
    if (mouseX < threshold) {
      container.scrollBy(-scrollSpeed, 0);
    } else if (mouseX > windowWidth - threshold) {
      container.scrollBy(scrollSpeed, 0);
    }

    // Check if the mouse is over the left half or right half of the over element
    if (crossedBoundaries.hasCrossedHalfwayX) {
      over.classList.remove('divider-before');
      over.classList.add('divider-after');
      this.before = false;
      this.after = true;
    } else if (source !== over) {
      over.classList.remove('divider-after');
      over.classList.add('divider-before');

      this.before = true;
      this.after = false;
    }
  }

  _dragContentPage(event, source, over) {
    const sensorData = event.data.sensorEvent.data;
    const crossedBoundaries = this._calculateOverlapPercentage(sensorData, over);
    const overIndex = this._index(over);

    if (over.classList.contains('js-program-module')) {
      if (!over.querySelectorAll('[class*="js-content-page-"]').length) {
        if (!over.querySelector('.placeholder-content-page-card')) {
          const placeholder = document.createElement('div');
          placeholder.classList.add(
            'placeholder-content-page-card'
          );

          const container = over.querySelector('.js-draggable-cp-cards');
          container.appendChild(placeholder);
        }
      }
    }

    if (crossedBoundaries.hasCrossedHalfwayY) {
      over.classList.remove('divider-above');
      over.classList.add('divider-below');
      this.before = false;
      this.after = true;
      this.contentPageIndex = overIndex + 1
    } else if (source !== over) {
      over.classList.remove('divider-below');
      over.classList.add('divider-above');

      this.before = true;
      this.after = false;

      this.contentPageIndex = overIndex;
    }
  }

  _insertIntoProgramModule(draggedElement) {
    const overElement = this.over;
    let topParentType = overElement.dataset.moduleType;

    let programModuleId = overElement.closest('.js-program-module')
      .dataset.elementId;

    if (!this._elementCanBeDropped(draggedElement, topParentType)) {
      return false;
    }

    const container = overElement.querySelector('.js-draggable-cp-cards');

    if (container.children.length === 0) {
      container.appendChild(draggedElement);
    } else {
      container.insertBefore(draggedElement, container.children[this.contentPageIndex]);
    }

    this._moveContentPageIntoProgramModule(draggedElement, programModuleId);
    draggedElement.getElementsByClassName('card-cover')[0].classList.add('hidden');

    // Clean up
    this.over = null;
  }

  _insertBeforeOrAfterElement(event, draggedElement, originalSource) {
    const overElement = this.over;
    const parentNode = overElement.parentNode;

    if (!draggedElement.classList.contains('js-program-module')) {
      let topParentType = overElement.closest('.js-program-module').dataset.moduleType;

      if (!this._elementCanBeDropped(draggedElement, topParentType)) {
        this._removeAllDividers();

        return false;
      }
    }

    if (this.before) {
      parentNode.insertBefore(draggedElement, overElement);

      this._reorderElement(
        draggedElement.dataset.elementType,
        draggedElement.dataset.elementId,
        overElement.dataset.elementId,
        'before'
      );
    } else if (this.after) {
      const afterElement = overElement.nextElementSibling;

      parentNode.insertBefore(draggedElement, afterElement);

      this._reorderElement(
        draggedElement.dataset.elementType,
        draggedElement.dataset.elementId,
        overElement.dataset.elementId,
        'after'
      );
    }

    // remove the card cover
    const cover = draggedElement.getElementsByClassName('card-cover')[0];
    cover.classList.add('hidden');
  }

  _elementCanBeDropped(draggedElement, topParentType) {
    let canBeDropped = false;

    draggedElement.dataset.allowedModuleTypes.split(',').forEach(item => {
      if (item === topParentType) {
        canBeDropped = true;
      }
    });

    return canBeDropped;
  }

  _removeAllDividers() {
    document.querySelectorAll('.divider-before, .divider-after, .divider-below, .divider-above').forEach((elem) => {
      elem.classList.remove('divider-before')
      elem.classList.remove('divider-after')
      elem.classList.remove('divider-above')
      elem.classList.remove('divider-below')
    })

    document.querySelectorAll('.placeholder-content-page-card').forEach((elem) => {
      elem.remove();
    })
  }

  _performRequest(url, method, body, element) {
    ajax({
      url: url,
      type: method,
      data: body,
      headers: {
        'Content-Type': 'application/json'
      },
      dataType: "script",
      success: () => {
        this._highlightElement(element);
      }
    });
  }

  _highlightElement(elem) {
    if (elem.classList.contains('js-program-module')) {
      elem = elem.querySelector('.card-content');
    }

    elem.classList.add('highlight');

    setTimeout(() => {
      elem.classList.remove('highlight');
    }, 3000);
  }

  _calculateOverlapPercentage(event, over) {
    const { clientX, clientY } = event;
    const overRect = over.getBoundingClientRect();

    // Calculate the midpoint of the over element
    const midPointX = overRect.left + overRect.width / 2;
    const midPointY = overRect.top + overRect.height / 2;

    // Check if the mouse is over the top half or bottom half of the over element
    const hasCrossedHalfwayX = clientX > midPointX;
    const hasCrossedHalfwayY = clientY > midPointY;

    return { hasCrossedHalfwayX, hasCrossedHalfwayY };
  }

  _index(elem) {
    return Array.prototype.indexOf.call(elem.parentNode.children, elem);
  }
}
